import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import learnToSwim from '../images/learn-to-swim.svg';
import logo from '../images/logo.svg';
import { Link } from 'gatsby';
import Button from '../components/Button';

const IndexPage = () => {
  const homeContent = () => (
    <div className="homepage-header-content">
      <div>
        <img src={learnToSwim} alt="Learn to Swim with" className="learn-to-swim" draggable="false" />
      </div>
      <div>
        <img src={logo} alt="The Little Dolphin Academy" className="home-logo" draggable="false" />
      </div>
    </div>
  );

  const features = [
    {
      name: 'Preschool Lessons',
      content: `30 Minute Lessons, Students aged 3-4 years, 1-1 Private Lessons, 1-2 Private Lessons and Group Lessons.
      
Instructor Supporting in the Water`,
      image: (
        <StaticImage
          src="../images/lessons/preschool.webp"
          alt="Preschool Lessons"
          height={200}
          width={200}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      link: '/lessons/preschool',
    },
    {
      name: "Children's Lessons",
      content: `30 Minute Lessons, Students aged 5-12 years, 1-1 Private Lessons, 1-2 Private Lessons and Group Lessons.
      
Instructor Supporting in the Water`,
      image: (
        <StaticImage
          src="../images/lessons/children.webp"
          alt="Child Lessons"
          height={200}
          width={200}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      link: '/lessons/children',
    },
    {
      name: 'Adult Lessons',
      content: `30 Minute Lessons, Students aged 13+, 1-1 Private Lessons, 1-2 Private Lessons and Group Lessons.
      
Instructor Supporting in the Water`,
      image: (
        <StaticImage
          src="../images/lessons/adult.webp"
          alt="Adult Lessons"
          height={200}
          width={200}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      link: '/lessons/adult',
    },
  ];

  return (
    <Layout styles="homepage" title="Welcome" content={homeContent()}>
      <div className="homepage-content-container">
        {features.length > 0
          ? features.map((feature, index) => (
              <div className="homepage-feature" key={`feature-${index}`}>
                <div className="feature-image">{feature.image}</div>
                <div className="feature-content-container">
                  <div className="feature-name">{feature.name}</div>
                  <div className="feature-content">{feature.content}</div>
                  <div className="feature-link">
                    <Link href={feature.link} className="button blue-button">
                      See all {feature.name}
                    </Link>
                  </div>
                </div>
              </div>
            ))
          : 'No features'}
      </div>
    </Layout>
  );
};

export default IndexPage;
